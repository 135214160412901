// extracted by mini-css-extract-plugin
export var container = "Oferta-module--container--gj1-2";
export var root = "Oferta-module--root--2BeQP";
export var title = "Oferta-module--title--1Zb5x";
export var subtitle = "Oferta-module--subtitle--2r4YF";
export var grid = "Oferta-module--grid--_VDTm";
export var serviceImageContainer = "Oferta-module--serviceImageContainer--2ehUq";
export var serviceImage = "Oferta-module--serviceImage--BO-UX";
export var serviceContent = "Oferta-module--serviceContent--I06vO";
export var serviceTitle = "Oferta-module--serviceTitle--2-ezB";
export var serviceDescriptions = "Oferta-module--serviceDescriptions--1q24Y";
export var serviceDescriptionItem = "Oferta-module--serviceDescriptionItem--2D4sk";
export var service = "Oferta-module--service--2Tgpd";
export var disclaimer = "Oferta-module--disclaimer--1lotc";