import React from 'react';

import Button from '~/components/Button';
import availableServices from '~/data/services';
import routes from '~/routes';

import * as styles from './Oferta.module.scss';

const Services: React.FC = () => {
  const { services } = availableServices;

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <h1 className={styles.title}>Oferta</h1>
        <p className={styles.subtitle}>
          W gabinecie oferujemy szeroką gamę zabiegów i usług stomatologicznych. Współpracujemy również z innymi
          specjalistami z zakresu endodoncji, chirurgii oraz ortodoncji.
          <br />
          <br />
          <Button
            as={{
              tag: 'Link',
              to: routes.PRICES,
            }}
          >
            Zobacz nasz cennik
          </Button>
        </p>

        <div className={styles.grid}>
          {services.map((service, idx) => (
            <div key={idx} className={styles.service}>
              <div className={styles.serviceImageContainer}>
                <img className={styles.serviceImage} src={service.image} alt={service.title} role='presentation' />
              </div>
              <div className={styles.serviceContent}>
                <h3 className={styles.serviceTitle}>{service.title}</h3>
                <ul className={styles.serviceDescriptions}>
                  {service.description.map((s, idx) => (
                    <li className={styles.serviceDescriptionItem} key={idx}>
                      - {s}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.disclaimer}>Oferujemy roczną gwarancję na uzupełnienia</div>
      </div>
    </section>
  );
};

export default Services;
